import React, { Component } from 'react';
import {Table, Icon, Button, Input, Form, List} from 'semantic-ui-react';

import "../Admin.css"


class HelpModuleEditing extends Component {
    constructor(props){
        super(props)
    }

    state = {selectedQuestion: 1}

    componentDidMount(){
        //this.fetchAllData()
    }

    fetchAllData = () => {
        let url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyquestions'
        fetch(url)
        .then(response => response.json())
        .then((questions_fetched) => {
            url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyanswers'
            fetch(url)
            .then(response => response.json())
            .then((answers_fetched) => {
                this.setState({
                    questions: questions_fetched,
                    answers: answers_fetched
                })
            })
        })
    }

    handleChange = ( e, { name, value } ) => this.setState( { [ name ]: value } )

    renderAnswers(){
        if(this.props.answers.length > 0){
            return(
                <List divided relaxed>
                    {this.props.answers.map(answer =>{
                        if(answer.question_id == this.state.selectedQuestion){
                            let showValue = true;
                            if(showValue = true){
                                return(
                                    <List.Item>
                                        <List.Content floated='right'>
                                            <Icon style={{cursor:'pointer'}} name='edit' onClick={() => this.props.renderEditMenu(4, answer, "answers")}/>
                                        </List.Content>
                                        <List.Content>
                                            {answer.content}
                                        </List.Content>
                                    </List.Item>
                                )
                            }else{
                                return(
                                    <Input>hey</Input>
                                )
                            }
                        }
                    })}
                </List>
            )
        }
    }

    renderQuestions(){
        if(this.props.questions.length > 0){
            return(
                <List divided relaxed>
                    {this.props.questions.map(question =>{
                        return(
                            <List.Item>
                                <List.Content floated='right'>
                                    <Icon style={{cursor:'pointer'}} name='edit' onClick={() => this.props.renderEditMenu(4, question, "questions")}/>
                                    <Button onClick={() => {this.setState({selectedQuestion: question.question_id})}}>Select</Button>
                                </List.Content>
                                <List.Content>
                                    {question.content}
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
            )
        }
    }

    render() {
        return (
        <div>
            <div className={"questLeft"}>
                <p className={"editFormHeaders"}>Questions:</p>
                {this.renderQuestions()}
            </div>
            <div className={"questRight"}>
                <p className={"editFormHeaders"}>Answers:</p>
                {this.renderAnswers()}
            </div>
        </div>
        )
    }
}

export default HelpModuleEditing;