import React, { Component } from 'react';
import {Icon} from 'semantic-ui-react';

class SurveySelectionItem extends Component {
    constructor(props){
        super(props);
        this.icon = "eye"
    }
    state = {visible: true}

    hideItem = () => {
        if(this.state.visible && this.props.isGvisible){
            this.icon = "eye slash"
            this.setState({visible: false})
            this.props.hide(this.props.survey.survey_id, true)
        }else{
            this.icon = "eye"
            this.setState({visible: true})
            this.props.hide(this.props.survey.survey_id, false)
        }
    }

    render() {
        return (
                <p style={{color:this.props.color, cursor:'pointer'}} onClick={this.hideItem}>
                    {this.props.isGvisible?<Icon name={this.icon}/>:null}
                    {this.props.survey.company}
                </p>
        );
    }
}

export default SurveySelectionItem;