import React, { Component } from 'react';
import './CutOut.css';

class CutOut extends Component {
    render() {
        return (
            <div className='cutOut'/>
        );
    }
}

export default CutOut;