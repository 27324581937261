import React, { Component } from 'react';
import './Background.css';

class Background extends Component {
    render() {
        return (
            <div className='background'>
                <div className='gradient' style={{width: this.props.gWidth + "%"}}/>
            </div>
        );
    }
}

export default Background;