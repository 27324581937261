import React, { Component } from "react";
import { Button } from "semantic-ui-react";
export default class Modal extends Component {
  render() {
    const modalStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };
    const boxStyle = {
      borderRadius: "30px",
      padding: "10px",
      fontSize: "1.25em"
    }
    const titleStyle = {
      fontsize: "2em"
    }
    const buttonDiv = {
      display: "flex",
      justifyContent: "center",
    }
    return (
      <div class="modal show fade" style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content" style={boxStyle}>
            <div class="modal-header">
              <h5 class="modal-title" style={titleStyle}>
                Please read before starting the survey
              </h5>
            </div>
            <div class="modal-body">
              <p>
                This simulation maturity assessment gives you instant access to
                a simplified engineering simulation maturity model of your
                company, as you see it. In addition you will see how this
                compares to all other respondents.
              </p>
              <p>
                Engineering simulation is here defined as the simulation of
                physics phenomenon as for example mechanics, fluid mechanics,
                electromagnetics, electronics and optics, together with adjacent
                technologies like materials engineering, systems engineering and
                robust design optimization.
              </p>
              <p>
                The questions cover 5 specific domains which in combination
                constitutes the overall maturity: Strategy, Organisation,
                People, Processes and Technology. Carefully think through the
                current situation within your company as you see it before
                selecting an alternative. The survey consist of 10 questions,
                please scroll down to the end before submitting.
              </p>
              <div style={buttonDiv}>
                <Button
                  type="button"
                  color="blue"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.hide}
                >OK</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
