import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./index.css";
import "semantic-ui-css/semantic.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import LoginPage from "./scenes/login/LoginPage";
import Dashboard from "./scenes/dashboard/Dashboard";
import Help from "./scenes/help/Help";
import GDPR from "./scenes/gdpr/GDPR";
import Questionnaire from "./scenes/questionnaire/Questionnaire";
import Admin from "./scenes/admin/Admin";

import Nav from "./nav";

import * as serviceWorker from "./serviceWorker";

function App() {
  return (
    <Router>
      {/*<Nav/>*/}
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/help" component={Help} />
        <Route path="/gdpr" component={GDPR} />
        <Route path="/questionnaire" component={Questionnaire} />
        <Route path="/admin" component={Admin} />
      </Switch>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
