import React, { Component } from "react";
import mainlogo from "../../assets/images/logo_white.svg";
import "./GDPR.css";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

class GDPR extends Component {
  state = { code: "" };

  componentDidMount() {
    //const {handle} = this.props.match.params
    const { enteredCode } = this.props.location.state;
    this.setState({ code: enteredCode });
  }
  render() {
    return (
      <div>
        <div className="headerMain">
          <img
            className="offset-4 col-4 py-2 headerLogo"
            src={mainlogo}
            alt={"EDR Medeso"}
          />
        </div>

        <div className="container">
          <div className="GDPR offset-1 col-10 offset-lg-3 col-lg-6">
            <div className="GDPRTitle .align-middle py-3">
              <h2 className="TitleFont">Background to the maturity assessment</h2>
            </div>
            <div className="GDPRBody p-4">
              <p style={{ fontSize: "1.25em" }}>
                Thanks for participating and filling out this survey. The
                questionnaire is designed to collect opinions and thoughts on
                topics related to simulation from EDRMedeso's customers. The
                answers will be summarized and analyzed in a maturity model to
                provide you with insights on how to improve your use of
                simulation in the future.
              </p>
              <p style={{ fontSize: "1.25em" }}>
                By filling out this form you agree that we will process your
                data in line with our{" "}
                <a
                  target="_blank"
                  href="https://digitallabs.edrmedeso.com/privacy-policy"
                >
                  privacy policy.
                </a>
              </p>
              <p style={{ fontSize: "1.25em" }}>
                {" "}
                For any questions or comments please contact us at
                info@edrmedeso.com.
              </p>
              <p style={{ fontSize: "1.25em" }}>
                {" "}
                Once again, thanks for participating in this survey.
              </p>
            </div>
            <div className="GDPRButton">
              <Link
                to={{
                  pathname: "/questionnaire",
                  state: { submittedCode: this.state.code },
                }}
              >
                <Button color="blue">Continue</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GDPR;
