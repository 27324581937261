import React, { Component } from "react";
import SurveyGraphLine from "../../dashboard/components/SurveyGraphLine";
import SurveyGraphScatter from "../../dashboard/components/SurveyGraphScatter";
import "../Questionnaire.css";
import { Button, Icon } from "semantic-ui-react";
import { exportComponentAsPNG } from "react-component-export-image";

class GraphResult extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.fileName = { fileName: "SMI Graph Results" };
  }

  renderGraph = () => {
    return (
      <div ref={this.componentRef} className="row">
        <div className="col-12 col-lg-6 graph">
          <SurveyGraphScatter
            surveyData={this.props.surveyData}
            focusData={this.props.focusData}
            hideFullscreenBtn={true}
            justFocus={this.props.justFocus}
            isQuest={true}
          />
        </div>
        <div className="col-12 col-lg-6 graph">
          <SurveyGraphLine
            surveyData={this.props.surveyData}
            focusData={this.props.focusData}
            hideFullscreenBtn={true}
            justFocus={this.props.justFocus}
            isQuest={true}
          />
        </div>
      </div>
    );
  };

  render() {
    let modalStyle = {
      display: "block",
      backgroundColor: "rgba(0,0,0,0.8)",
    };
    return (
      <div class="modal show fade" style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <div className="">{this.renderGraph()}</div>
            </div>
            <div class="modal-footer">
              <Button
                onClick={() => this.props.onFullscreen()}
                className=""
                color="blue"
              >
                Exit
              </Button>
              <Button
                onClick={() =>
                  exportComponentAsPNG(this.componentRef, this.fileName)
                }
                className=""
                color="orange"
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraphResult;
