import React, { Component } from "react";
import "../Dashboard.css";
import SurveyGraphLine from "./SurveyGraphLine";
import SurveyGraphScatter from "./SurveyGraphScatter";

class SurveyGraph extends Component {
  constructor(props) {
    super(props);
    this.isJustFocus = this.props.selected.length <= 0;
    this.hiddenSurvey = [];
    this.hiddenFocus = {};
  }

  state = { surveyData: [], focusData: [] };

  componentDidMount() {
    let url;

    if (this.isJustFocus) {
      url =
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/surveys/" +
        this.props.focus.entry_code +
        "/respondents/XYvalues";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          try {
            let array = [];
            result.X_Y_values.map((cords) => {
              array = [...array, cords];
              return false;
            });
            this.setState({
              surveyData: array,
            });
          } catch (err) {}
        });
      url =
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/surveys/" +
        this.props.focus.entry_code +
        "/respondents/Average";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          try {
            this.setState({
              focusData: [result.Average_values],
            });
          } catch (err) {}
        });
    } else {
      url =
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/surveys/" +
        this.props.focus.entry_code +
        "/respondents/Average";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          try {
            result.Average_values.survey_id = this.props.focus.survey_id;
            this.setState({
              focusData: [result.Average_values],
            });
          } catch (err) {}
        });

      this.props.selected.map((survey) => {
        url =
          (process.env.REACT_APP_API_URL || "http://localhost:3000") +
          "/api/surveys/" +
          survey.entry_code +
          "/respondents/Average";
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            try {
              result.Average_values.survey_id = survey.survey_id;
              this.setState((prevState) => ({
                surveyData: [...prevState.surveyData, result.Average_values],
              }));
            } catch (err) {}
          });
        return false;
      });
    }
  }

  checkHideModule = (ID, Boolean) => {
    if (ID > 0) {
      if (Boolean) {
        let found = false;
        this.state.focusData.map((focus) => {
          if (focus.survey_id === ID) {
            this.hiddenFocus = focus;
            this.setState({ focusData: [] });
            found = true;
          }
          return false;
        });
        if (!found) {
          this.state.surveyData.map((survey) => {
            if (survey.survey_id === ID) {
              this.hiddenSurvey.push(survey);
              this.setState((prevState) => ({
                surveyData: prevState.surveyData.filter(
                  (surveyData) => surveyData !== survey
                ),
              }));
            }
            return false;
          });
        }
      } else {
        if (this.hiddenFocus.survey_id === ID) {
          this.setState((prevState) => ({
            focusData: [...prevState.focusData, this.hiddenFocus],
          }));
        } else {
          this.hiddenSurvey.map((survey) => {
            if (survey.survey_id === ID) {
              let current = this.hiddenSurvey.find(function (item) {
                return item.survey_id === ID;
              });

              this.setState((prevState) => ({
                surveyData: [...prevState.surveyData, current],
              }));
              this.hiddenSurvey = this.hiddenSurvey.filter(function (item) {
                return item.survey_id !== ID;
              });
            }
            return false;
          });
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !(
        prevProps.hideData === this.props.hideData &&
        prevProps.hideBool === this.props.hideBool
      )
    ) {
      this.checkHideModule(this.props.hideData, this.props.hideBool);
    }
  }

  render() {
    return (
      <div className="surveyGraph">
        <SurveyGraphScatter
          focusData={this.state.focusData}
          surveyData={this.state.surveyData}
          justFocus={this.isJustFocus}
          onFullscreen={this.props.onFullscreen}
        />
        <SurveyGraphLine
          focusData={this.state.focusData}
          surveyData={this.state.surveyData}
          justFocus={this.isJustFocus}
          onFullscreen={this.props.onFullscreen}
        />
      </div>
    );
  }
}

export default SurveyGraph;
