export const curveValue = [
    {
        "x": 0.1,
        "y": 10.0003162
    },
    {
        "x": 0.15,
        "y": 10.0013071
    },
    {
        "x": 0.2,
        "y": 10.0035777
    },
    {
        "x": 0.25,
        "y": 10.0078125
    },
    {
        "x": 0.3,
        "y": 10.0147885
    },
    {
        "x": 0.35,
        "y": 10.0253652
    },
    {
        "x": 0.4,
        "y": 10.0404772
    },
    {
        "x": 0.45,
        "y": 10.0611285
    },
    {
        "x": 0.5,
        "y": 10.0883883
    },
    {
        "x": 0.55,
        "y": 10.123387
    },
    {
        "x": 0.6,
        "y": 10.1673129
    },
    {
        "x": 0.65,
        "y": 10.2214098
    },
    {
        "x": 0.7,
        "y": 10.2869744
    },
    {
        "x": 0.75,
        "y": 10.3653545
    },
    {
        "x": 0.8,
        "y": 10.4579467
    },
    {
        "x": 0.85,
        "y": 10.5661953
    },
    {
        "x": 0.9,
        "y": 10.6915901
    },
    {
        "x": 0.95,
        "y": 10.8356658
    },
    {
        "x": 1,
        "y": 11
    },
    {
        "x": 1.05,
        "y": 11.1862126
    },
    {
        "x": 1.1,
        "y": 11.3959646
    },
    {
        "x": 1.15,
        "y": 11.6309567
    },
    {
        "x": 1.2,
        "y": 11.8929292
    },
    {
        "x": 1.25,
        "y": 12.1836601
    },
    {
        "x": 1.3,
        "y": 12.5049654
    },
    {
        "x": 1.35,
        "y": 12.8586974
    },
    {
        "x": 1.4,
        "y": 13.2467446
    },
    {
        "x": 1.45,
        "y": 13.6710306
    },
    {
        "x": 1.5,
        "y": 14.1335139
    },
    {
        "x": 1.55,
        "y": 14.636187
    },
    {
        "x": 1.6,
        "y": 15.1810757
    },
    {
        "x": 1.65,
        "y": 15.770239
    },
    {
        "x": 1.7,
        "y": 16.4057683
    },
    {
        "x": 1.75,
        "y": 17.0897867
    },
    {
        "x": 1.8,
        "y": 17.8244491
    },
    {
        "x": 1.85,
        "y": 18.6119411
    },
    {
        "x": 1.9,
        "y": 19.454479
    },
    {
        "x": 1.95,
        "y": 20.3543094
    },
    {
        "x": 2,
        "y": 21.3137085
    },
    {
        "x": 2.05,
        "y": 22.3349818
    },
    {
        "x": 2.1,
        "y": 23.420464
    },
    {
        "x": 2.15,
        "y": 24.5725183
    },
    {
        "x": 2.2,
        "y": 25.7935363
    },
    {
        "x": 2.25,
        "y": 27.0859375
    },
    {
        "x": 2.3,
        "y": 28.4521691
    },
    {
        "x": 2.35,
        "y": 29.8947056
    },
    {
        "x": 2.4,
        "y": 31.4160487
    },
    {
        "x": 2.45,
        "y": 33.0187266
    },
    {
        "x": 2.5,
        "y": 34.7052942
    },
    {
        "x": 2.55,
        "y": 36.4783325
    },
    {
        "x": 2.6,
        "y": 38.3404484
    },
    {
        "x": 2.65,
        "y": 40.2942747
    },
    {
        "x": 2.7,
        "y": 42.3424693
    },
    {
        "x": 2.75,
        "y": 44.4877156
    },
    {
        "x": 2.8,
        "y": 46.7327218
    },
    {
        "x": 2.85,
        "y": 49.0802209
    },
    {
        "x": 2.9,
        "y": 51.5329704
    },
    {
        "x": 2.95,
        "y": 54.0937521
    },
    {
        "x": 3,
        "y": 56.7653718
    },
    {
        "x": 3.05,
        "y": 59.5506593
    },
    {
        "x": 3.1,
        "y": 62.4524681
    },
    {
        "x": 3.15,
        "y": 65.4736751
    },
    {
        "x": 3.2,
        "y": 68.6171804
    },
    {
        "x": 3.25,
        "y": 71.8859074
    },
    {
        "x": 3.3,
        "y": 75.2828025
    },
    {
        "x": 3.35,
        "y": 78.8108345
    },
    {
        "x": 3.4,
        "y": 82.4729951
    },
    {
        "x": 3.45,
        "y": 86.2722982
    },
    {
        "x": 3.5,
        "y": 90.2117802
    },
    {
        "x": 3.55,
        "y": 94.2944994
    },
    {
        "x": 3.6,
        "y": 98.5235359
    },
    {
        "x": 3.65,
        "y": 102.9019919
    },
    {
        "x": 3.7,
        "y": 107.4329909
    },
    {
        "x": 3.75,
        "y": 112.1196781
    },
    {
        "x": 3.8,
        "y": 116.9652199
    },
    {
        "x": 3.85,
        "y": 121.9728039
    },
    {
        "x": 3.9,
        "y": 127.1456387
    },
    {
        "x": 3.95,
        "y": 132.486954
    },
    {
        "x": 4,
        "y": 138
    },
    {
        "x": 4.05,
        "y": 143.6880477
    },
    {
        "x": 4.1,
        "y": 149.5543886
    },
    {
        "x": 4.15,
        "y": 155.6023346
    },
    {
        "x": 4.2,
        "y": 161.8352177
    },
    {
        "x": 4.25,
        "y": 168.2563901
    },
    {
        "x": 4.3,
        "y": 174.8692243
    },
    {
        "x": 4.35,
        "y": 181.6771122
    },
    {
        "x": 4.4,
        "y": 188.6834658
    },
    {
        "x": 4.45,
        "y": 195.8917168
    },
    {
        "x": 4.5,
        "y": 203.3053163
    },
    {
        "x": 4.55,
        "y": 210.9277349
    },
    {
        "x": 4.6,
        "y": 218.7624624
    },
    {
        "x": 4.65,
        "y": 226.8130082
    },
    {
        "x": 4.7,
        "y": 235.0829004
    },
    {
        "x": 4.75,
        "y": 243.5756864
    },
    {
        "x": 4.8,
        "y": 252.2949323
    },
    {
        "x": 4.85,
        "y": 261.2442233
    },
    {
        "x": 4.9,
        "y": 270.4271631
    },
    {
        "x": 4.95,
        "y": 279.8473741
    },
    {
        "x": 5,
        "y": 289.5084972
    }
]