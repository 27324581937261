import React, { Component } from 'react';

class StepsField extends Component {

    render() {
        return (
            <div>
                <p>Selected Issue : {this.props.view[0]}</p>
                <p>Selected Topic : {this.props.view[1]}</p>
                {this.props.list.map(steps => {
                    if(steps.issue_id === this.props.view[0] && steps.topic_id === this.props.view[1]){
                        return <p>{steps.step_id + ". " + steps.content}</p>
                    }
                    return false
                })}
            </div>
        );
    }
}

StepsField.propTypes = {

};

export default StepsField;