import React, { Component } from 'react';
import propTypes from 'prop-types';
import {Container} from 'semantic-ui-react';
import '../Help.css';

class TopicField extends Component {

    onClick = () => {
        this.props.onClick(this.props.topic.issue_id, this.props.topic.topic_id)
    }

    render() {
        return (
            <div>
                <Container className='helpContainer' onClick={this.onClick}>{this.props.topic.content}</Container>
            </div>
        );
    }
}

TopicField.propTypes = {
    topic: propTypes.shape(propTypes.shape({
        topic_id: propTypes.number.isRequired,
        issue_id: propTypes.number.isRequired,
        content: propTypes.string.isRequired
    }))
};

export default TopicField;