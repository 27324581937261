import React, { Component } from 'react';
import {Input, Button} from 'semantic-ui-react';

import Header from '../../components/Header';
import Background from '../../components/Background';
import CutOut from '../../components/CutOut';

import IssueField from './components/IssueField';
import StepsField from './components/StepsField';

import './Help.css';


class Help extends Component {
    state = {issueList:[], topicList:[], stepList:[], selectedView:[1,1]}

    componentDidMount(){
        this.checkUserValidity()
        let url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/issues'
        fetch(url)
        .then(response => response.json())
        .then((resultA) => {
            url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/topics'
            fetch(url)
            .then(response => response.json())
            .then((resultB) => {
                url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/steps'
                fetch(url)
                .then(response => response.json())
                .then((resultC) => {
                    this.setState({
                        issueList: resultA,
                        topicList: resultB,
                        stepList: resultC
                    })
                })
            })
        })
    }

    checkUserValidity = () => {
        const tokenStr = localStorage.getItem('token')

        if(tokenStr === null){
            this.props.history.push( { pathname: '/'})
        }else{
            const item = JSON.parse(tokenStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem('token')
                this.props.history.push( { pathname: '/'})
            }
        }
    }

    renderSteps = (issID,topID) => {
        this.setState({
            selectedView: [issID, topID]
        })
    }

    generateIssues = () => {
        if(this.state.issueList.length > 0 && this.state.topicList.length > 0){
            return(
                this.state.issueList.map(issue => {
                        let topics = []
                        this.state.topicList.map(topic => {
                            if(topic.issue_id === issue.issue_id){
                                topics.push(topic)
                            }
                            return false;
                        })
                        return <IssueField key={issue.issue_id} issue={issue} topics={topics} click={this.renderSteps}/>
                })
            )
        }
    }

    render() {
        return (
            <div>
                <Background/>
                <CutOut/>
                <div className='helpDiv'>
                    <div className='helpTopHalf'>
                        <p className='logoText'>Help</p>
                    </div>
                    <div className='helpBotHalf'>
                        <div className="helpSearch">
                            <div className='wrapper'>
                            <Input
                            icon='search'
                            iconPosition='left'
                            //name='searchSurvey'
                            //value={searchSurvey}
                            placeholder='How can we help you?'
                            //onChange={this.handleChange}
                            />
                            <Button>Search</Button>
                            </div>
                        </div>
                        <div className='helpLeft'>
                            {this.generateIssues()}
                        </div>
                        <div className='helpRight'>
                            <StepsField list={this.state.stepList} view={this.state.selectedView}/>
                        </div>
                    </div>
                </div>
                <Header/>
            </div>
        );
    }
}

export default Help;
