import React, { Component } from 'react'
import {Input, Form} from 'semantic-ui-react';


export default class QuestionEditForm extends Component {
    constructor(){
        super()
    }

    state = {
        content: "",
    }

    componentDidMount(){
        this.setState({content: this.props.content})
    }

    onEditChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    onEditQuest = (quest) => {
        let data, content
        if(this.state.content){
            content = this.state.content
            data = {content}
            if(quest.type == "questions"){
                fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyquestions/' + quest.question_id, {
                    method: 'PATCH',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'authorization': `Bearer ${localStorage.getItem('token')}`
                }})
                .then(response => response.json())
                .then((result) => {
                    this.props.fetchQuestions()
                })
                .catch(Error => {
                    console.log("Error:")
                    console.log(Error)
                })
            }else if(quest.type == "answers"){
                fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyquestions/' + quest.question_id + '/surveyanswers/' + quest.answer_id, {
                    method: 'PATCH',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'authorization': `Bearer ${localStorage.getItem('token')}`
                }})
                .then(response => response.json())
                .then((result) => {
                    this.props.fetchQuestions()
                })
                .catch(Error => {
                    console.log("Error:")
                    console.log(Error)
                })
            }
        }
        this.props.onEditExit()
    }

    render() {
        return (
            <Form className="newSurveyForm" onSubmit={() => this.onEditQuest(this.props.selectedQuestion)}>
                <Form.Field>
                    <p className={"editFormHeaders"}>{this.props.selectedQuestion.content} </p>
                    <Input placeholder='Enter new phrase here'
                    name="content"
                    value={this.state.content} 
                    onChange={this.onEditChange}/>
                </Form.Field>
                <Form.Button
                content="Submit"
                />
            </Form> 
        )
    }
}
