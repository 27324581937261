import React, {Component} from 'react';
import mainLogo from '../assets/images/logo_white.svg';
import avatarLogo from '../assets/images/avatar.svg';
import './Header.css';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


class Header extends Component {
    state = {
        showHub: false,
        userName: null,
        userPriv: null}

    componentDidMount(){
        this.fetchUserData()
    }

    fetchUserData = () => {
        const tokenStr = localStorage.getItem('token')

        if(tokenStr != null){
            const item = JSON.parse(tokenStr)
            this.setState({
                userName: item.user,
                userPriv: item.role
            })
        }
    }

    showAvatarHub = () => {
        this.setState(prevState => ({
            showHub: !prevState.showHub}))
    }

    onSignOut = () => {
        localStorage.removeItem('token')
    }

    render(){
        return(
            <div className='headerMain'>
                <img className='headerLogo' src={mainLogo} alt={'EDR Medeso'}/>
                <p className='headerTitle'>Simulation Maturity Assessment</p>
                {!this.state.showHub
                ?   <img className="avatarLogo" onClick={this.showAvatarHub} src={avatarLogo} alt={'logo'}/>
                :   
                <div className="avatarHub">
                    <img className="avatarLogo" onClick={this.showAvatarHub} src={avatarLogo} alt={'logo'}/>
                    <div className="infoHub">
                        <p>{this.state.userName}</p>
                        <p>{this.state.userPriv}</p>
                        <Button.Group vertical>
                            <Link to='/dashboard'>
                                <Button id="headerBtn" >Dashboard</Button>
                            </Link>
                            <Link to='/admin'>
                                <Button id="headerBtn">Admin Module</Button>
                            </Link>
                            {/*
                            <Link to='/help'>
                               <Button id="headerBtn">Help</Button>
                            </Link>
                            */}
                            <Link to='/'>
                                <Button onClick={() => this.onSignOut()} id="headerBtn">Sign Out</Button>
                            </Link>
                        </Button.Group>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default Header;