import React, { Component } from "react";
import {
  ScatterChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Scatter,
  Tooltip,
} from "recharts";
import SurveyGraphHeader from "./SurveyGraphHeader";
import ScatterBackground from "../../../assets/images/scatter.png";
import "../Dashboard.css";
const factor = 1.5;
const max = Math.pow(5, factor);

class SurveyGraphScatter extends Component {
  constructor(props) {
    super(props);
    this.val_float = "left";
  }

  dataFuzziness = (data) => {
    let fuzzyData = [];
    data.map((point) => {
      let x, y;
      //generates random number between -0.1 and 0.1
      x = point.x + (Math.floor(Math.random() * 20) - 10) / 100;
      y = point.y + (Math.floor(Math.random() * 20) - 10) / 100;

      x = Math.pow(x, factor);
      y = Math.pow(y, factor);

      //rounds number to 1 decimal place
      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;

      //if cases for points outside boundaries
      if (x > max) {
        x = max;
      } else if (x < 0) {
        x = 0;
      }

      if (y > max) {
        y = max;
      } else if (y < 0) {
        y = 0;
      }

      //add to array
      fuzzyData.push({ x, y });
    });
    return fuzzyData;
  };

  scaleValues(data) {
    let scaledValues = [];
    data.map((point) => {
      let x, y, Xvalue, Yvalue;

      x = Math.pow(point.x, factor);
      y = Math.pow(point.y, factor);

      Xvalue = point.Xvalue;
      Yvalue = point.Yvalue;

      //rounds number to 1 decimal place
      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;

      //add to array
      scaledValues.push({ x, y, Xvalue, Yvalue });
    });

    return scaledValues;
  }

  render() {
    return (
      <div className="surveyScatter">
        <div className="graphTitle">
          <p style={{ float: "left", fontWeight: "bold" }}>Overall</p>
          <p style={{ float: "right" }}>
            {this.props.justFocus
              ? this.props.surveyData.length
              : this.props.surveyData.length + this.props.focusData.length}{" "}
            Respondents
          </p>
        </div>
        <div className="imgWrap">
          <img alt={""} className="graphIMG" src={ScatterBackground} />
        </div>
        <SurveyGraphHeader
          justFocus={this.props.justFocus}
          s={this.props.surveyData}
          f={this.props.focusData}
          id="scatter"
          onFullscreen={this.props.onFullscreen}
          hideFullscreenBtn={this.props.hideFullscreenBtn}
          isQuest={this.props.isQuest}
        />
        <div className="scatterWrapper">
          <ResponsiveContainer>
            <ScatterChart>
              <XAxis type="number" dataKey="x" domain={[0, max]} hide />
              <YAxis type="number" dataKey="y" domain={[0, max]} hide />
              <Scatter
                name="surveys"
                data={this.dataFuzziness(this.props.surveyData)}
                fill="#F48800"
                isAnimationActive={false}
              />
              <Scatter
                name="focus"
                stroke="#EB1E23"
                strokeWidth={5}
                data={this.scaleValues(this.props.focusData)}
                fill="#EB1E23"
                isAnimationActive={false}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default SurveyGraphScatter;
