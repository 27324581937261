import React, { Component } from 'react';
import {Table, Icon, Button, Input, Form} from 'semantic-ui-react';


class UserTable extends Component {
    constructor(props){
        super(props)
    }

    generateUserHeaders = () => {
        return(
            <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email Address</Table.HeaderCell>
                <Table.HeaderCell>Password</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
            </Table.Row>
        )
    }

    generateUserItems = () => {
        if(this.props.users.data.length > 0){
            return(
                this.props.users.data.map(user => (
                    <Table.Row key={user.admin_id}>
                        <Table.Cell>{user.admin_id}</Table.Cell>
                        <Table.Cell>{user.first_name}</Table.Cell>
                        <Table.Cell>{user.last_name}</Table.Cell>
                        <Table.Cell>{user.email_address}</Table.Cell>
                        <Table.Cell>{user.PASSWORD}</Table.Cell>
                        <Table.Cell><Icon style={{cursor:'pointer'}} name='edit' onClick={() => {this.props.renderEditMenu(1, user)}}/></Table.Cell>
                        <Table.Cell><Icon style={{cursor:'pointer'}} name='delete' onClick={() => this.props.onDeleteRequest(user ,"user")}/></Table.Cell>
                    </Table.Row>
            )))
        }
    }


    render() {
        return (
            <div>
                <Table striped>
                    <Table.Header>
                        {this.generateUserHeaders()}
                    </Table.Header>
                    <Table.Body>
                        {this.generateUserItems()}
                    </Table.Body>
                </Table>
                <Button onClick={() => this.props.renderEditMenu(2)}>Create New User</Button>
            </div>
        );
    }
}

export default UserTable;