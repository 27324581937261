import React, { Component } from "react";
import { Table, Icon, Button, Input, Form } from "semantic-ui-react";

class SurveyTable extends Component {
  constructor(props) {
    super();
  }

  generateSurveyItems = () => {
    if (this.props.surveys.length > 0) {
      return this.props.surveys.map((survey) => (
        <Table.Row key={survey.survey_id}>
          <Table.Cell>{survey.company}</Table.Cell>
          <Table.Cell>
            {survey.survey_creation_date.substring(0, 10)}
          </Table.Cell>
          <Table.Cell>{survey.entry_code}</Table.Cell>
          <Table.Cell>{survey.industry}</Table.Cell>
          <Table.Cell>{survey.headquarter}</Table.Cell>
          <Table.Cell>{survey.actual_responses}</Table.Cell>
          <Table.Cell>{survey.company_size}</Table.Cell>
          <Table.Cell>
            <Icon
              style={{ cursor: "pointer" }}
              name="edit"
              onClick={() => {
                this.props.renderEditMenu(0, survey);
              }}
            />
          </Table.Cell>
          <Table.Cell>
            <Icon
              style={{ cursor: "pointer" }}
              name="delete"
              onClick={() => this.props.onDeleteRequest(survey, "survey")}
            />
          </Table.Cell>
          {/*<Table.Cell><Icon style={{cursor:'pointer'}} name='delete' onClick={() => this.setState({showConfirmWin: true, delTarget: {survey, type:'survey'}})}/></Table.Cell>*/}
        </Table.Row>
      ));
    }
  };

  generateSurveyHeaders = () => {
    return (
      <Table.Row>
        <Table.HeaderCell>Company</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Code</Table.HeaderCell>
        <Table.HeaderCell>Industry</Table.HeaderCell>
        <Table.HeaderCell>Headquarter</Table.HeaderCell>
        <Table.HeaderCell>Respondents</Table.HeaderCell>
        <Table.HeaderCell>Company Size</Table.HeaderCell>
        <Table.HeaderCell>Edit</Table.HeaderCell>
        <Table.HeaderCell>Delete</Table.HeaderCell>
      </Table.Row>
    );
  };

  render() {
    return (
      <div>
        <Table striped>
          <Table.Header style={{ position: "sticky", top: 0 }}>
            {this.generateSurveyHeaders()}
          </Table.Header>
          <Table.Body>{this.generateSurveyItems()}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default SurveyTable;
