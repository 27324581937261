import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import mainlogo from "../../assets/images/logo_white.svg";
import key from "../../assets/images/round-vpn_key-24px.svg";

import "bootstrap/dist/css/bootstrap.css";
import "./LoginPage.css";

class LoginPage extends Component {
  state = {
    code: "",
    email_address: "",
    password: "",
    available: true,
    isExpanded: false,
    settingWidth: "0%",
    errorMessage: "",
    errorTrue: false,
  };

  handleCodeInput = () => {
    const inputVal = document.getElementById("inputID").value;
    this.setState({ code: inputVal });
  };

  handleCodeSubmit = () => {
    fetch(
      (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/surveys/" +
        this.state.code +
        "/available"
    )
      .then((response) => response.json())
      .then((result) => {
        try {
          if (result.Available === true) {
            this.props.history.push({
              pathname: "/gdpr",
              state: { enteredCode: this.state.code },
            });
          }
          this.setState({ available: result.Available });
        } catch (err) {}
      });
  };

  handleChangeU = () => {
    const emailVal = document.getElementById("emailID").value;
    this.setState({ email_address: emailVal });
  };

  handleChangeP = () => {
    const passVal = document.getElementById("passID").value;
    this.setState({ password: passVal });
  };

  handleSubmit = () => {
    const { email_address, password } = this.state;
    let data = { email_address, password };

    fetch(
      (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/employees/login",
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let d = new Date();
        const item = {
          token: result.cookieData.token,
          user: result.cookieData.user,
          role: result.cookieData.role,
          expiry: d.getTime() + 60 * 60 * 1000,
        };
        localStorage.setItem("token", JSON.stringify(item));
        if (result.message) {
          this.setState({ errorMessage: result.message, errorTrue: true });
        } else {
          this.props.history.push({ pathname: "/dashboard" });
        }
      })
      .catch((Error) => {});

  };

  handleExpand = (isExpanded) => {
    this.checkUserValidity();
    if (isExpanded) {
      this.setState({
        isExpanded: !isExpanded,
        settingWidth: "0%",
      });
    } else {
      this.setState({
        isExpanded: !isExpanded,
        settingWidth: "100%",
      });
    }
  };

  checkUserValidity = () => {
    const tokenStr = localStorage.getItem("token");

    if (tokenStr != null) {
      const item = JSON.parse(tokenStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem("token");
      } else {
        this.props.history.push({ pathname: "/dashboard" });
      }
    }
  };

  render() {
    return (<>
       <div
              className="loginSlide"
              style={{ width: this.state.settingWidth, left: this.state.isExpanded ? 0: -500}}
            >
              <Button
                className="btnLogin"
                id="exitButton"
                onClick={() => this.handleExpand(this.state.isExpanded)}
              >
                Exit
              </Button>
              <Form
                id="form"
                className="loginForm"
                onSubmit={this.handleSubmit}
              >
                <div id="formContainer">
                  <div style={{ width: "447" }}>
                    <Form.Field>
                      <div style={{ height: "100%" }}>
                        <div id="labelContainer">
                          <label
                            style={{
                              fontSize: "1.65em",
                              whiteSpace: "nowrap",
                              paddingBottom: "0.75em",
                              textAlign: "center"
                            }}
                          >
                            Email address
                          </label>
                        </div>
                        <div id="inputContainer">
                          <div>
                            <input
                              id="emailID"
                              className="inputField"
                              placeholder="Enter your email address"
                              onChange={this.handleChangeU}
                            />
                          </div>
                        </div>
                        <div id="labelContainer">
                          <label
                            style={{
                              fontSize: "1.65em",
                              whiteSpace: "nowrap",
                              paddingBottom: "0.75em",
                            }}
                          >
                            Password
                          </label>
                        </div>
                        <div id="inputContainer">
                          <div>
                            <input
                              id="passID"
                              type="password"
                              className="inputField"
                              placeholder="Enter your password"
                              onChange={this.handleChangeP}
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Field>
                    <div className="btnSubmitWrap">
                      <Form.Button
                        className="btnSubmit"
                        id="submitButton"
                        content="Submit"
                      />
                    </div>
                    {this.state.errorTrue ? (
                      <Message negative>
                        <Message.Header>ERROR!</Message.Header>
                        <p>{this.state.errorMessage}</p>
                      </Message>
                    ) : null}
                  </div>
                </div>
              </Form>
            </div>
      <div className="app row h-100">
        <div className="leftSide col-12 col-lg-6 d-flex align-items-center" style={(this.state.isExpanded?{visibility: "hidden"}:null)}>
          <div className="col-12">
            <img className="col-4 col-lg-6 my-4" alt={"logo"} src={mainlogo} />
            <p className="d-none d-lg-block logoTextA">
              Simulation Maturity Assessment
            </p>
          </div>
        </div>

        <div className="rightSide col-12 col-lg-6 d-flex align-items-center" style={(this.state.isExpanded?{visibility: "hidden"}:null)}>
          <div className="buttonG col-12">
            {/* <Button className="btnSignUp" onClick={this.handleSignIn}>Sign up</Button> */}

            <Button
              className="btnLogin"
              id="loginButton"
              onClick={() => this.handleExpand(this.state.isExpanded)}
            >
              Admin Login
            </Button>
            <Form
              id="form"
              className="loginForm"
              onSubmit={this.handleCodeSubmit}
            >
              <div id="formContainer">
                <div style={{ width: "447" }}>
                  <Form.Field>
                    <div style={{ height: "100%" }}>
                    <div id="labelContainer">
                    <label
                      style={{
                      fontSize: "1.65em",
                      whiteSpace: "nowrap",
                      paddingBottom: "0.75em",
                      }}>Try out our maturity assessment
                    </label>
                   </div> 
                  <Link
                    to={{
                      pathname: "/gdpr",
                      state: { enteredCode: "default" },
                    }}
                  >
                    <Button id="noCodeButton">
                      Start assessment
                    </Button>
                  </Link>
                      <div id="labelContainer">
                        <label
                          style={{
                            fontSize: "1.65em",
                            whiteSpace: "nowrap",
                            paddingBottom: "0.75em",
                          }}
                        >
                          Are you taking the test with your team?
                        </label>
                      </div>
                      <div id="inputContainer">
                        <img src={key} alt={""} className="imageKey"></img>
                        <div id="inputContainerWrapper">
                          <input
                            id="inputID"
                            className="inputField"
                            placeholder="Enter your code"
                            onChange={this.handleCodeInput}
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Field>
                  <div className="btnSubmitWrap">
                    <Form.Button
                      className="btnSubmit"
                      id="submitButton"
                      content="Start team assessment"
                    />
                  </div>
                  {this.state.available ? null : (
                    <Message negative>
                      <Message.Header>ERROR!</Message.Header>
                      <p>Sorry, but that code is invalid!</p>
                    </Message>
                  )}
                  
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
    );
  }
}

export default LoginPage;
