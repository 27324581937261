import React, { Component } from "react";
import "./Questionnaire.css";
import { Progress, Form, Button, Message, Dropdown, Label } from "semantic-ui-react";
import FormField from "./components/FormField";
import mainLogo from "../../assets/images/logo_white.svg";
import GraphResult from "./components/GraphResult";
import Modal from "./components/Message";

class Questionnaire extends Component {
  constructor(p) {
    super();
    this.result = {
      role: "",
      department: "",
      company_size: "",  
      industry: "", 
      headquarter: "",
      entry_code: "",
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
    };
    this.complete = false;
  }

  state = {
    questions: [],
    surveyData: [],
    focusData: [],
    progress: 0,
    btncolor: "blue",
    showGraphs: false,
    showMessage: true,
    quickFix: false,
    role: "",
    department: "",
    preFormComplete: false,
    roleList: [],
    departmentList: [],
    company_size: "",  
    industry: "", 
    headquarter: "",
    countryList: [], 
    industryList: [],
  };

  componentDidMount() {
    const { submittedCode } = this.props.location.state;
    this.result.entry_code = submittedCode;
    this.fetchCountryandIndustry()

    let url =
      (process.env.REACT_APP_API_URL || "http://localhost:3000") +
      "/api/surveyquestions";
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          questions: result,
        });
      });

    let tempR = [],
      tempD = [];
    fetch(
      (process.env.REACT_APP_API_URL || "http://localhost:3000") + "/api/roles"
    )
      .then((response) => response.json())
      .then((result) => {
        try {
          result.map((el, i) => {
            tempR = [...tempR, { key: i, text: el.role, value: el.role }];
          });
        } catch (err) {}
        fetch(
          (process.env.REACT_APP_API_URL || "http://localhost:3000") +
            "/api/departments"
        )
          .then((response) => response.json())
          .then((result) => {
            try {
              result.map((el, i) => {
                tempD = [
                  ...tempD,
                  { key: i, text: el.department, value: el.department },
                ];
              });
            } catch (err) {}
            this.setState({
              roleList: tempR,
              departmentList: tempD,
            });
          });
      });
  }
  fetchCountryandIndustry = () => {
    let tempC = [], tempI = []
    fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/countries')
    .then(response => response.json())
    .then((result) => {
        try{
            result.map((el,i) => {
                tempC = [...tempC,{key:i,text:el.country,value:el.country}]
                return false;
            })
        }catch(err){}
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/industries')
        .then(response => response.json())
        .then((result) => {
            try{
                result.map((el,i) => {
                    tempI = [...tempI,{key:i,text:el.industry,value:el.industry}]
                    return false;
                })
            }catch(err){}
            this.setState({
                countryList: tempC,
                industryList: tempI
            })
        })
    })
  }
  
  incrementProgress = (name, value, chosen) => {
    let questionNum = "q" + name;
    this.result[questionNum] = value - 1;
    if (!chosen) {
      this.setState({
        progress: this.state.progress + 1,
      });
    }
  };

  handleSubmit = () => {
    if (this.state.progress !== 10) {
      this.setState({ btncolor: "red" });
    } else {
      fetch(
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
          "/api/respondents/graph",
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(this.result),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.setState({ focusData: [result.X_Y_values] });
        })
        .catch((Error) => {
          console.log("Error:");
          console.log(Error);
        });

      fetch(
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
          "/api/surveys/respondents/AllXYvalues"
      )
        .then((response) => response.json())
        .then((result) => {
          try {
            let array = [];
            result.X_Y_values.map((cords) => {
              array = [...array, cords];
              return false;
            });
            this.setState({
              surveyData: array,
            });
          } catch (err) {}
        });
      this.setState({ btncolor: "black", showGraphs: true });
    }
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmitWithCode = () => {
    if (this.state.role === "" || this.state.department === "") {
      this.setState({ btncolor: "red" });
    } else {
      this.result.role = this.state.role;
      this.result.department = this.state.department;
      this.setState({ btncolor: "blue", preFormComplete: true });
    }
  }

  handleSubmitNoCode = () => {
    if (this.state.role === "" || this.state.department === "" || this.state.industry === "" || this.state.headquarter === "" || this.state.company_size === "") {
      this.setState({ btncolor: "red" });
    } else {
      this.result.role = this.state.role;
      this.result.department = this.state.department;
      this.result.company_size = this.state.company_size;
      this.result.industry = this.state.industry;
      this.result.headquarter = this.state.headquarter;
      this.setState({ btncolor: "blue", preFormComplete: true });
    }
  }

  handleFilterSubmit = () => {
    if (this.result.entry_code === "default") {
      this.handleSubmitNoCode();
    } else {
      this.handleSubmitWithCode();
    }
  }

  exitGraph = () => {
    window.location.assign(
      "https://go.edrmedeso.com/simulation-maturity-index-confirmation"
    );
  };

  generateQuestions = () => {
    if (this.state.questions.length > 0) {
      return this.state.questions.map((question) => (
        <FormField
          key={question.question_id}
          question={question}
          click={this.incrementProgress}
        />
      ));
    }
  };

  render() {
    const { role, department, industry, headquarter, company_size } = this.state;
    const companyList = [
      {key: '0-50', value:'0-50', text: '0-50'},
      {key: '51-250', value:'51-250', text: '51-250'},
      {key: '251-1000', value:'251-1000', text: '251-1000'},
      {key: '1001-10000', value:'1001-10000', text: '1001-10000'},
      {key: '10001-50000', value:'10001-50000', text: '10001-50000'},
      {key: '50001+', value:'50001+', text: '50001+'}
  ]
    return (
      <div className="">
        <div className="headerMain">
          <img
            className="headerLogo offset-4 col-4 py-2"
            src={mainLogo}
            alt={"EDR Medeso"}
          />
        </div>

        {this.state.preFormComplete ? (
          <div>
            <div className="questDiv col-10 offset-1 col-lg-6 offset-lg-3 p-4 my-5">
              <div className="questBody">
                <Form className="surveyForm">
                  {this.generateQuestions()}
                  <div className="quest-body-sub"></div>
                </Form>
              </div>
              <div className="questHeader">
                {this.state.progress === 10 ? (
                  <Progress
                    className="questProgress"
                    id="progressDone"
                    percent={this.state.progress * 10}
                    color="blue"
                    success
                  />
                ) : (
                  <Progress
                    className="questProgress"
                    id="progress"
                    percent={this.state.progress * 10}
                    color="blue"
                  />
                )}          
                <Button
                  className="subBtn"
                  color={this.state.btncolor}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
                {this.state.btncolor === "red" ? (
                  <Message negative>
                    <Message.Header>ERROR!</Message.Header>
                    <p>Form is incomplete!</p>
                  </Message>
                ) : null}
              </div>
            </div>

            {this.state.showMessage ? (
              <Modal hide={() => this.setState({ showMessage: false })} />
            ) : null}
          </div>
        ) : (
          <div className="questDiv2 col-10 offset-1 col-lg-6 offset-lg-3 p-4 my-5">
            <div className="questFillInfo">
                <h2 className="TitleFont formTitle">Please enter the following data</h2>
              <Form className="informationForm col-12 col-lg-6" name="preForm" >
              {this.result.entry_code === "default" ? <>
                <Form.Field>
                    <Dropdown
                        placeholder='Select Industry'
                        fluid
                        search
                        selection
                        name='industry'
                        value={industry}
                        onChange={this.handleChange}
                        options={this.state.industryList}
                    />
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        placeholder='Select Headquarter Location'
                        fluid
                        search
                        selection
                        name='headquarter'
                        value={headquarter}
                        onChange={this.handleChange}
                        options={this.state.countryList}
                    />
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        placeholder='Total number of employees'
                        fluid
                        search
                        selection
                        name='company_size'
                        value={company_size}
                        onChange={this.handleChange}
                        options={companyList}
                    />
                </Form.Field>
                </> : null}
                <Form.Field>
                  <Dropdown
                    placeholder="Select Role"
                    fluid
                    search
                    selection
                    name="role"
                    value={role}
                    onChange={this.handleChange}
                    options={this.state.roleList}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    placeholder="Select Department"
                    fluid
                    search
                    selection
                    name="department"
                    value={department}
                    onChange={this.handleChange}
                    options={this.state.departmentList}
                  />
                </Form.Field>
                <Button
                  color={this.state.btncolor}
                  onClick={this.handleFilterSubmit}
                >
                  Continue
                </Button>
                {this.state.btncolor === "red" ? (
                  <Message className="mt-3" negative>
                    <Message.Header>ERROR!</Message.Header>
                    <p>Form is incomplete!</p>
                  </Message>
                ) : null}
              </Form>
            </div>
          </div>
        )}
        {this.state.showGraphs ? (
          <GraphResult
            surveyData={this.state.surveyData}
            focusData={this.state.focusData}
            onQgenerate={this.getAllGraphData}
            onFullscreen={this.exitGraph}
            justFocus={true}
          />
        ) : null}
      </div>
    );
  }
}

export default Questionnaire;
