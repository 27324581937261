import React, {Component} from 'react';
import {Button, Container} from 'semantic-ui-react';
import propTypes from 'prop-types';
import '../Dashboard.css';
import SurveySelectionItem from './SurveySelectionItem';

class SurveySelection extends Component {
    constructor(props){
        super()
        this.btnTitle = "Show Results"
        this.isGraphVisible = false
    }

    btnShowGraph = () =>{
        this.props.show()
        if(this.btnTitle === "Show Results"){
            this.isGraphVisible = true
            this.btnTitle = "Return to Dashboard"
        }else{
            this.isGraphVisible = false
            this.btnTitle = "Show Results"
        }
    }

    generateFocusedSurv = () =>{
        if(Object.keys(this.props.focus).length){
            return(
                <div>
                <p style={{color:'red'}}>FOCUSED: </p>
                <SurveySelectionItem isGvisible={this.isGraphVisible} hide={this.props.hide} color="red" survey={this.props.focus}/>
                </div>
            )
        }
    }
    
    generateSelectedSurvs = () =>{
        if(this.props.selected.length > 0){
            return(
                this.props.selected.map(survey => (
                    <SurveySelectionItem isGvisible={this.isGraphVisible} key={survey.survey_id} hide={this.props.hide} survey={survey}/>
                ))
            )
        }
    }

    render(){
        return(
            <div style={{height:'100%'}}>
                <Container style={{height:'90%'}}>
                    {this.generateFocusedSurv()}
                    {this.generateSelectedSurvs()}
                </Container>
                {this.isGraphVisible?<Button color="orange">Export</Button>:null}
                <Button onClick={this.btnShowGraph}>{this.btnTitle}</Button>
            </div>
        )
    }
}

SurveySelection.propTypes = {
    surveys: propTypes.arrayOf(propTypes.shape({
        survey_id: propTypes.number.isRequired,
        company: propTypes.string.isRequired,
        survey_creation_date: propTypes.string.isRequired,
        entry_code: propTypes.string.isRequired,
        company_size: propTypes.string.isRequired,
        actual_responses: propTypes.number.isRequired,
        industry: propTypes.string.isRequired,
        headquarter: propTypes.string.isRequired,
        created_by: propTypes.number.isRequired
    })),
};

export default SurveySelection;