import React, { Component } from 'react'
import {Input, Form, Dropdown} from 'semantic-ui-react';


export default class SurveyEditForm extends Component {
    constructor(){
        super()
    }

    state = {
        company_size: undefined, 
        company: undefined, 
        entry_code: undefined, 
        industry: undefined, 
        headquarter: undefined,
        countryList: [], 
        industryList: [],
    }

    componentDidMount(){
        this.fetchCountryandIndustry()
    }

    fetchCountryandIndustry = () => {
        let tempC = [], tempI = []
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/countries')
        .then(response => response.json())
        .then((result) => {
            try{
                result.map((el,i) => {
                    tempC = [...tempC,{key:i,text:el.country,value:el.country}]
                    return false;
                })
            }catch(err){}
            fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/industries')
            .then(response => response.json())
            .then((result) => {
                try{
                    result.map((el,i) => {
                        tempI = [...tempI,{key:i,text:el.industry,value:el.industry}]
                        return false;
                    })
                }catch(err){}
                this.setState({
                    countryList: tempC,
                    industryList: tempI
                })
            })
        })
    }

    onEditChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }
    
    onSurveyEditSubmit = (survey) => {
        let company, industry, headquarter, company_size
        
        if(this.state.company){company = this.state.company}else{company = this.props.selectedSurvey.company}
        if(this.state.industry){industry = this.state.industry}else{industry = this.props.selectedSurvey.industry}
        if(this.state.headquarter){headquarter = this.state.headquarter}else{headquarter = this.props.selectedSurvey.headquarter}
        if(this.state.company_size){company_size = this.state.company_size}else{company_size = this.props.selectedSurvey.company_size}

        let data= {company, industry, headquarter, company_size}

        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveys/' + survey.survey_id, {
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        .then(response => response.json())
        .then((result) => {
            this.props.fetchSurveys()
        })
        .catch(Error => {
            console.log("Error:")
            console.log(Error)
        })
        this.props.onEditExit()
    }

    render() {
        const companyList = [
            {key: '0-50', value:'0-50', text: '0-50'},
            {key: '51-250', value:'51-250', text: '51-250'},
            {key: '251-1000', value:'251-1000', text: '251-1000'},
            {key: '1001-10000', value:'1001-10000', text: '1001-10000'},
            {key: '10001-50000', value:'10001-50000', text: '10001-50000'},
            {key: '50001+', value:'50001+', text: '50001+'}
        ]
        return (
            <Form className="newSurveyForm" onSubmit={() => this.onSurveyEditSubmit(this.props.selectedSurvey)}>
                <Form.Field>
                    <p className={"editFormHeaders"}>{this.props.selectedSurvey.company}</p>
                    <Input placeholder='Company'
                    name="company"
                    value={this.state.company} onChange={this.onEditChange}/>
                </Form.Field>
                <Form.Field>
                    <p className={"editFormHeaders"}>{this.props.selectedSurvey.industry} </p>
                    <Dropdown
                        placeholder='Select Industry'
                        fluid
                        search
                        selection
                        name='industry'
                        value={this.state.industry}
                        onChange={this.onEditChange}
                        options={this.state.industryList}
                    />
                </Form.Field>
                <Form.Field>
                    <p className={"editFormHeaders"}>{this.props.selectedSurvey.headquarter} </p> 
                    <Dropdown
                        placeholder='Select Headquarter Location'
                        fluid
                        search
                        selection
                        name='headquarter'
                        value={this.state.headquarter}
                        onChange={this.onEditChange}
                        options={this.state.countryList}
                    />
                </Form.Field>
                <Form.Field>
                <p className={"editFormHeaders"}>{this.props.selectedSurvey.company_size} </p>
                    <Dropdown
                        placeholder='Select Company Size'
                        fluid
                        search
                        selection
                        name='company_size'
                        value={this.state.company_size}
                        onChange={this.onEditChange}
                        options={companyList}
                    />
                </Form.Field>
                <Form.Button
                    content="Submit"
                />
            </Form>
        )
    }
}
