import React, { Component } from 'react';
import {Button, Form, Input, Dropdown, Icon} from 'semantic-ui-react';

const sizeOptions = [
        {key: '0-50', value:'0-50', text: '0-50'},
        {key: '51-250', value:'51-250', text: '51-250'},
        {key: '251-1000', value:'251-1000', text: '251-1000'},
        {key: '1001-10000', value:'1001-10000', text: '1001-10000'},
        {key: '10001-50000', value:'10001-50000', text: '10001-50000'},
        {key: '50001+', value:'50001+', text: '50001+'}
    ]

class NewSurvey extends Component {
    constructor(props){
        super()

    }

    state = { company: '', industry: '', headquarter: '', company_size: '', created_by: 1, countryList: [], industryList: [], generatedCode: ''}

    componentDidMount(){
        let tempI = [], tempC = []
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/countries')
        .then(response => response.json())
        .then((result) => {
            try{
                result.map((el,i) => {
                    tempC = [...tempC,{key:i,text:el.country,value:el.country}]
                    return false;
                })
            }catch(err){}
            fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/industries')
            .then(response => response.json())
            .then((result) => {
                try{
                    result.map((el,i) => {
                        tempI = [...tempI,{key:i,text:el.industry,value:el.industry}]
                        return false;
                    })
                }catch(err){}
                this.setState({
                    countryList: tempC,
                    industryList: tempI
                })
            })
        })
    }

    handleChange = (e, {name, value}) => this.setState({ [name]: value})

    handleSubmit = () => {
        const {company, industry, headquarter, company_size, created_by} = this.state
        let data = {company, company_size, industry, headquarter, created_by}
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveys', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
        }}).then(response => response.json())
        .then((result) => {
            this.setState({generatedCode: result.entry_code})
        })
        .catch(Error => {
            console.log("Error:")
            console.log(Error)
        })

        this.setState({
            company : '',
            industry : '',
            headquarter : '',
            company_size: ''})
    }

    render() {
        const {company, headquarter, industry, industryList, countryList, company_size} = this.state
        return (
            <div className="surveyView">
                <div className="surveyTitle">
                    New Survey
                </div>
                <Button icon onClick={this.props.triggerBack}>
                    <Icon
                    name='arrow alternate circle left outline'
                    size='large'
                    />
                </Button>
                <Form className="newSurveyForm" onSubmit = {this.handleSubmit}>
                    <Form.Field>
                        <label style={{fontSize: '2em', whiteSpace: "nowrap", paddingBottom: "0.75em"}}>Create a new survey .</label>
                        <Input
                        icon='briefcase'
                        name='company'
                        value={company}
                        placeholder='Company Name'
                        onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder='Select Industry'
                            fluid
                            search
                            selection
                            name='industry'
                            value={industry}
                            onChange={this.handleChange}
                            options={industryList}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder='Select Headquarter Location'
                            fluid
                            search
                            selection
                            name='headquarter'
                            value={headquarter}
                            onChange={this.handleChange}
                            options={countryList}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder='Select Company Size'
                            fluid
                            search
                            selection
                            name='company_size'
                            value={company_size}
                            onChange={this.handleChange}
                            options={sizeOptions}
                        />
                    </Form.Field>
                    <Form.Button
                        content="Create"
                    />
                </Form>
                {this.state.generatedCode === ''?null:<div style={{textAlign:'center'}}><p>Survey successfully created!</p><p>Please share this code: {this.state.generatedCode}</p></div>}
            </div>
        );
    }
}

export default NewSurvey;
