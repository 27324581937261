import React, { Component } from "react";
import "./Dashboard.css";
import SurveyOverview from "./components/SurveyOverview";
import NewSurvey from "./components/NewSurvey";
import SurveySelection from "./components/SurveySelection";
import SurveyButtonNav from "./components/SurveyButtonNav";
import Background from "../../components/Background";
import Header from "../../components/Header";
import CutOut from "../../components/CutOut";
import SurveyGraph from "./components/SurveyGraph";
import SurveyGraphFullscreen from "./components/SurveyGraphFullscreen";
import SurveyFilter from "./components/SurveyFilter";

class Dashboard extends Component {
  constructor() {
    super();
    this.IDfullscreen = "";
    this.lineData = [];
    this.focusData = [];
    this.surveyData = [];
  }

  state = {
    showOverview: true,
    showFullscreen: false,
    showGraph: false,
    selectSurv: [],
    dataList: [],
    focusSurv: {},
    hideData: [],
    hideBool: [],
    showFilter: false,
    filterList: [],
    filterType: "",
    surveyFiltersC: [],
    surveyFiltersI: [],
    searchSurveyHolder: [],
  };

  componentDidMount() {
    this.checkUserValidity();
  }

  checkUserValidity = () => {
    const tokenStr = localStorage.getItem("token");

    if (tokenStr === null) {
      this.props.history.push({ pathname: "/" });
    } else {
      const item = JSON.parse(tokenStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem("token");
        this.props.history.push({ pathname: "/" });
      }
    }
  };

  onNewSurveyClick = () => {
    this.setState({
      showOverview: !this.state.showOverview,
      selectSurv: [],
      focusSurv: {},
    });
  };

  onSurveyAdd = (item) => {
    this.setState({ selectSurv: [...this.state.selectSurv, item] });
  };

  onSurveyRemove = (item) => {
    this.state.selectSurv.map((survey) => {
      if (survey.survey_id === item.survey_id) {
        this.setState((prevState) => ({
          selectSurv: prevState.selectSurv.filter(
            (selectSurv) => selectSurv !== survey
          ),
        }));
      }
    });
  };

  onSurveyFocus = (item) => {
    if (this.state.focusSurv !== item) {
      this.setState({
        focusSurv: item,
      });
    }
  };

  onSurveyFocusRemove = (item) => {
    if (this.state.focusSurv.survey_id === item.survey_id) {
      this.setState({
        focusSurv: {},
      });
    }
  };

  onSurveyAllSelect = (surveys) => {
    let array = [];
    surveys.map((survey) => {
      if (this.state.focusSurv) {
        if (survey.survey_id !== this.state.focusSurv.survey_id) {
          array = [...array, survey];
        }
      } else {
        array = [...array, survey];
      }
    });
    this.setState({
      selectSurv: array,
    });
  };

  onSurveyAllDeselect = () => {
    this.setState({
      selectSurv: [],
      focusSurv: {},
    });
  };

  onSurveyException = () => {
    this.setState({
      selectSurv: [...this.state.selectSurv, this.state.focusSurv],
    });
  };

  showGraphBtn = () => {
    this.setState((prevState) => ({
      showGraph: !prevState.showGraph,
    }));
  };

  onRenderFullscreen = (id, sur, foc, ofoc) => {
    this.setState((prevState) => ({
      showFullscreen: !prevState.showFullscreen,
    }));
    this.IDfullscreen = id;
    this.focusData = foc;
    this.surveyData = sur;
    this.onlyFocus = ofoc;
  };

  onSurveyHide = (item, boolean) => {
    this.setState({ hideData: item, hideBool: boolean });
  };

  onShowFilter = (list, type) => {
    this.setState({
      showFilter: !this.state.showFilter,
      filterList: list,
      filterType: type,
    });
  };

  onRenderFilter = () => {
    if (this.state.showFilter) {
      return (
        <SurveyFilter
          type={this.state.filterType}
          list={this.state.filterList}
          onSubmit={this.onFilterSelect}
          onExit={() => this.setState({ showFilter: false })}
        />
      );
    }
  };

  onFilterSelect = (list) => {
    if (this.state.filterType === "Industry") {
      this.setState({ surveyFiltersI: list, showFilter: false });
    } else if (this.state.filterType === "Country") {
      this.setState({ surveyFiltersC: list, showFilter: false });
    }
  };

  onSearchSurvey = (searchSurvey) => {
    this.setState({ searchSurvey: searchSurvey });
  };

  render() {
    return (
      <div>
        <Background />
        <CutOut />
        <SurveyButtonNav
          onSearchSurvey={this.onSearchSurvey}
          onChange={this.onChange}
          click={this.onNewSurveyClick}
        />

        {this.state.showOverview ? (
          <SurveyOverview
            add={this.onSurveyAdd}
            remove={this.onSurveyRemove}
            focus={this.onSurveyFocus}
            defocus={this.onSurveyFocusRemove}
            allSelect={this.onSurveyAllSelect}
            allDeselect={this.onSurveyAllDeselect}
            transfer={this.onSurveyException}
            filter={this.onShowFilter}
            filterC={this.state.surveyFiltersC}
            filterI={this.state.surveyFiltersI}
            searchSurvey={this.state.searchSurvey}
          />
        ) : (
          <NewSurvey triggerBack={this.onNewSurveyClick} />
        )}

        {this.state.showGraph ? (
          <SurveyGraph
            hideData={this.state.hideData}
            hideBool={this.state.hideBool}
            focus={this.state.focusSurv}
            selected={this.state.selectSurv}
            onFullscreen={this.onRenderFullscreen}
          />
        ) : null}

        <div className="surveySelection">
          <div className="surveyTitle">Survey Selection</div>
          <SurveySelection
            focus={this.state.focusSurv}
            selected={this.state.selectSurv}
            show={this.showGraphBtn}
            hide={this.onSurveyHide}
          />
        </div>

        <Header />

        {this.state.showFullscreen ? (
          <SurveyGraphFullscreen
            id={this.IDfullscreen}
            surveyData={this.surveyData}
            focusData={this.focusData}
            onFullscreen={this.onRenderFullscreen}
            justFocus={this.onlyFocus}
            hideFullscreenBtn={true}
          />
        ) : null}

        {this.onRenderFilter()}
      </div>
    );
  }
}

export default Dashboard;
