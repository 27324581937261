import React, { Component } from 'react';
import propTypes from 'prop-types';
import TopicField from './TopicField';

class IssueField extends Component {
    
    render() {
        return (
            <div style={{padding: '1em'}}>
                <p style={{ textDecoration: 'underline', fontSize: '1.75em', marginBottom:'0.6em'}}>{this.props.issue.content}</p>
                {this.props.topics.map(topic => (
                    <TopicField key={topic.topic_id} topic={topic} onClick={this.props.click} />
                ))}
            </div>
        );
    }
}

IssueField.propTypes = {
    issues: propTypes.arrayOf(propTypes.shape({
        issue_id: propTypes.number.isRequired,
        content: propTypes.string.isRequired
    })),
    topics: propTypes.arrayOf(propTypes.shape({
        topic_id: propTypes.number.isRequired,
        issue_id: propTypes.number.isRequired,
        content: propTypes.string.isRequired
    }))
};

export default IssueField;