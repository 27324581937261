import React, { Component } from 'react';
import '../Dashboard.css'
import { Dropdown,Button,Icon} from 'semantic-ui-react';

class SurveyFilter extends Component {

    onSubmit = () => {
        try{
            this.props.onSubmit(this.state.value)
        }catch(err){
            this.onClear()
        }
    }

    onClear = () => {
        this.props.onSubmit([])
    }

    onExit = () => {
        this.props.onExit([])
    }

    render() {
        return (
            <div>
                <div className='graphFullscreenTint'/>
                <div className='filterView'>
                    <div className='filterHeader'>
                        <p style={{bottom: 0}}>{this.props.type} Filter</p>
                    </div>
                    <div className='filterBody'>
                        <Dropdown placeholder='Choose one or several filters...' onChange={(e, { value }) => this.setState({ value })} search fluid multiple selection options={this.props.list}/>
                    </div>
                    <div className='filterBodyButton'>
                        <Button onClick={() => this.onExit()} icon><Icon name="arrow alternate circle left outline"/></Button>
                        <Button onClick={() => this.onSubmit()} color='orange'>Submit</Button>
                        <Button onClick={() => this.onClear()} color='red'>Clear Filters</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurveyFilter;