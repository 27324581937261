import React, { Component } from 'react';
import {Button, Input} from 'semantic-ui-react'
import '../Dashboard.css'

class SurveyButtonNav extends Component {
    constructor(props){
        super()
    }
    state={
        searchSurvey: ''
    }

    handleChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    render() {
        return (
            <div className='surveyButtonNav'>
                <Input
                icon='search'
                iconPosition='left'
                name='searchSurvey'
                value={this.state.searchSurvey}
                placeholder='Search Survey ...'
                onChange={this.handleChange}
                />
                <Button onClick={() => this.props.onSearchSurvey(this.state.searchSurvey)}>Search</Button>
                <Button onClick={this.props.click}>New Survey</Button>
            </div>
        );
    }
}

export default SurveyButtonNav;