import React, { Component } from 'react';
import {Form, Radio} from 'semantic-ui-react';
import '../Questionnaire.css';

class FormField extends Component {
    state = {answers: []}

    componentDidMount(){
        let url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyquestions/'+ this.props.question.question_id +'/surveyanswers'
        fetch(url)
        .then(response => response.json())
        .then((result) => {
            this.setState({
                answers: result
            })
        })
        .catch()
    }

    handleChange = (e,{value, name}) => {
        let chosen = true;
        this.setState({
            value,
            chosen
        })
        this.props.click(name, value, this.state.chosen)
    }

    render() {
        return (
            <div className='formField'>
                <p>{this.props.question.question_id +". "+ this.props.question.content}</p>
                {this.state.answers.map(answer => (
                    <Form.Field key={answer.answer_id}>
                        <Radio
                        label={answer.content}
                        name={(answer.question_id).toString()}
                        value={answer.answer_id}
                        checked={this.state.value === answer.answer_id}
                        onChange={this.handleChange}
                        />
                    </Form.Field>
                ))}
            </div>
        );
    }
}

export default FormField;
