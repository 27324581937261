import React, { Component } from 'react';
import {Icon, Button, Input, Form, Divider} from 'semantic-ui-react';

import Header from '../../components/Header';
import Background from '../../components/Background';
import CutOut from '../../components/CutOut';

import './Admin.css';
//import HelpTable from './components/HelpTable';
import QuestionTable from './components/QuestionTable';
import SurveyTable from './components/SurveyTable';
import UserTable from './components/UserTable';
import UserEditForm from './components/UserEditForm';
import SurveyEditForm from './components/SurveyEditForm';
import QuestionEditForm from './components/QuestionEditForm';
//import HelpEditForm from './components/HelpEditForm';

class Admin extends Component {
    constructor(){
        super()
    }

    state = {
        showEditMenu: false, showConfirmWin: false,
        delTarget : {},
        selectedSurvey: "",
        selectedUser: "",
        selectedQuestion: "",
        users: "", surveys: "",
        tableView: 0, editMenuType: 0, 
        answers: [], questions: [],
        content: ""
    }

    componentDidMount(){
        this.checkUserValidity()
        this.fetchSurveys()
        this.fetchUsers()
        this.fetchQuestions()
    }

    checkUserValidity = () => {
        const tokenStr = localStorage.getItem('token')

        if(tokenStr === null){
            this.props.history.push( { pathname: '/'})
        }else{
            const item = JSON.parse(tokenStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem('token')
                this.props.history.push( { pathname: '/'})
            }
        }
    }

    fetchSurveys = () => {
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveys', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        .then(response => response.json())
        .then((surveys) => {
            this.setState({
                surveys: surveys
            })
        }).catch()
    }

    fetchUsers = () => {
        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/employees', 
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        .then(response => response.json())
        .then((users) => {
            this.setState({
                users: users
            })
        }).catch()
    }

    fetchQuestions = () => {
        let url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyquestions'
        fetch(url)
        .then(response => response.json())
        .then((questions_fetched) => {
            url = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveyanswers'
            fetch(url)
            .then(response => response.json())
            .then((answers_fetched) => {
                this.setState({
                    questions: questions_fetched,
                    answers: answers_fetched
                })
            })
        })
    }

    onDeleteRequest = (item, type) => {
        this.setState({showConfirmWin: true, delTarget: {[type]:item, type:type}})
    }

    postDelete = (target) => {
        if(target.type === "survey"){
            fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/surveys/' + target.survey.entry_code, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('token')}`
            }})
            .then(response => response.json())
            .then((result) => {
                this.fetchSurveys()
            })
            .catch(Error => {
                console.log("Error:")
                console.log(Error)
            })
        }else{
            fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/employees/' + target.user.admin_id, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('token')}`
            }})
            .then(response => response.json())
            .then((result) => {
                this.fetchUsers()
            })
            .catch(Error => {
                console.log("Error:")
                console.log(Error)
            })
        }
        this.setState({showConfirmWin: false, delTarget: {}})
    }

    onEditChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    onEditExit = () => {
        this.setState({showEditMenu: false, selectedSurvey: "", selectedUser: "", selectedQuestion: "", content: "", userEditTitle: undefined, userEditSubTitle: undefined})
    }

    renderEditMenu = (num, item, type) => {
        switch(num){
            case 0: //survey
                this.setState({showEditMenu: !this.state.showEditMenu, editMenuType: 0, selectedSurvey: item})
                break;
            case 1: //edit user
                this.setState({showEditMenu: true, editMenuType: 1, selectedUser: item})
                break;
            case 2: //new user
                this.setState({showEditMenu: true, editMenuType: 2, userEditTitle: 'Create', userEditSubTitle: 'New User Details:'})
                break;
            // case 3: //help
            //     item.type = type
            //     this.setState({showEditMenu: true, editMenuType: 3, selectedHelp: item, content: item.content})
            //     break;
            case 4: //question
                item.type = type
                this.setState({showEditMenu: true, editMenuType: 4, selectedQuestion: item, content: item.content})
        }
    }

    renderConfirmation = (target) => {
        let description, title
        title = target.type

        if(target.type === "survey"){
            description = target.survey.company
        }else if(target.type === "user"){
            description = target.user.first_name + " " + target.user.last_name
        }
        return(
        <div className='adminDelConfirm'>
            <div className='adminDelWrap'>
                <p className='adminDelTitle'>Are you sure you want to delete {title}?</p>
                <Divider/>
                <p className='adminDelSubTitle'>{description}</p>
                <Button color='green' onClick={() => this.postDelete(target)}>Confirm</Button>
                <Button color='red' onClick={() => this.setState({showConfirmWin: false, delTarget:{}})} >Cancel</Button>
            </div>
        </div>
        )
    }

    renderTable = (table_nr) => {
        switch(table_nr){
            case 0: //render Survey
                return(<SurveyTable onDeleteRequest={this.onDeleteRequest} surveys={this.state.surveys} renderEditMenu={this.renderEditMenu} deleteSurvey={this.deleteSurvey}/>)
            case 1://render User
                return(
                    <div>
                        <UserTable onDeleteRequest={this.onDeleteRequest} users={this.state.users} renderEditMenu={this.renderEditMenu}/>
                    </div>
                )
            // case 2: //render Help
            //     return(<HelpTable renderEditMenu={this.renderEditMenu} />)
            case 3: // render Questions
                return(<QuestionTable answers={this.state.answers} questions={this.state.questions} renderEditMenu={this.renderEditMenu}/>)
            default:
                return(<SurveyTable surveys={this.state.surveys} renderEditMenu={this.renderEditMenu} deleteSurvey={this.deleteSurvey}/>)
        }
    }

    renderEdit = (editType) => {
        switch(editType){
            case 0: //EDIT SURVEY
                return(<SurveyEditForm fetchSurveys={this.fetchSurveys} onEditExit={this.onEditExit} selectedSurvey={this.state.selectedSurvey}/>)
            case 1: //EDIT USER
                return(<UserEditForm isEdit={true} fetchUsers={this.fetchUsers} selectedUser={this.state.selectedUser} onEditExit={this.onEditExit}/>)
            case 2: //NEW USER
                return(<UserEditForm isEdit={false} fetchUsers={this.fetchUsers} selectedUser={this.state.selectedUser} onEditExit={this.onEditExit}/>)   
            // case 3: //EDIT HELP
            //     return(<HelpEditForm onEditExit={this.onEditExit}/>)
            case 4: //EDIT QUESTIONS
                return(<QuestionEditForm content={this.state.content} fetchQuestions={this.fetchQuestions} onEditExit={this.onEditExit} selectedQuestion={this.state.selectedQuestion}/>)
        }
    }

    render() {
        return (
            <div>
                <Background/>
                <CutOut/>
                <div className='helpDiv'>
                    <div className='helpTopHalf'>
                        <p className='logoText'>Admin Module</p>
                    </div>
                    <div className='helpBotHalf'>
                        <div className='surveyButtons'>
                            <Button active={this.state.tableView == 0} onClick={() => this.setState({tableView: 0, showConfirmWin: false})}>Surveys</Button>
                            <Button active={this.state.tableView == 1} onClick={() => this.setState({tableView: 1, showConfirmWin: false})}>Users</Button>
                            {/*<Button active={this.state.tableView == 2} onClick={() => this.setState({tableView: 2, showConfirmWin: false})}>Help Module</Button>*/}
                            <Button active={this.state.tableView == 3} onClick={() => this.setState({tableView: 3, showConfirmWin: false})}>Questionnaire</Button>
                        </div>
                        {this.state.showConfirmWin?
                            this.renderConfirmation(this.state.delTarget)
                        :
                        <div className="surveyAdminView">
                            {this.renderTable(this.state.tableView)}
                        </div>
                        }       
                    </div>
                </div>
                <Header/>
                {this.state.showEditMenu ?
                <div>
                    <div className='graphFullscreenTint'/>
                    <div className='filterViewAdmin'>
                        <div className='filterHeaderAdmin'>
                            <p style={{bottom: 0}}>{this.state.userEditTitle || 'Edit'}</p>
                        </div>
                        <Divider/>
                        <div className='filterBodyAdmin'>
                            <p style={{ fontSize:'large'}}>{this.state.userEditSubTitle || 'Current:'}</p>
                            {this.renderEdit(this.state.editMenuType)}
                        </div>
                        <div className='filterBodyButton'>
                            <Button onClick={() => this.onEditExit()} icon><Icon name="arrow alternate circle left outline"/></Button>
                        </div>
                    </div>
                </div>: null}
            </div>
        );
    }
}

export default Admin;