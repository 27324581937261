import React, { Component } from "react";
import { Table, Checkbox, Icon, Radio } from "semantic-ui-react";
import propTypes from "prop-types";

class SurveyOverview extends Component {
  constructor() {
    super();
    this.checkboxList = {};
    this.radioList = {};
  }

  state = {
    surveys: [],
    countries: [],
    industries: [],
    allCheck: false,
  };

  componentDidMount() {
    fetch(
      (process.env.REACT_APP_API_URL || "http://localhost:3000") +
        "/api/surveys",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((surveys) => {
        fetch(
          (process.env.REACT_APP_API_URL || "http://localhost:3000") +
            "/api/countries"
        )
          .then((response) => response.json())
          .then((result) => {
            let countries = [];
            try {
              result.map((el, i) => {
                countries = [
                  ...countries,
                  { key: i, text: el.country, value: el.country },
                ];
                return false;
              });
            } catch (err) {}
            fetch(
              (process.env.REACT_APP_API_URL || "http://localhost:3000") +
                "/api/industries"
            )
              .then((response) => response.json())
              .then((result) => {
                let industries = [];
                try {
                  result.map((el, i) => {
                    industries = [
                      ...industries,
                      { key: i, text: el.industry, value: el.industry },
                    ];
                    return false;
                  });
                } catch (err) {}
                this.setState({
                  countries: countries,
                  industries: industries,
                  surveys: surveys,
                });
              });
          });
      })
      .catch();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filterC !== this.props.filterC ||
      prevProps.filterI !== this.props.filterI
    ) {
      this.updateFilter();
    }
  }

  updateFilter = () => {
    if (this.props.filterC.length > 0 || this.props.filterI.length > 0) {
      let filterC = "";
      let filterI = "";
      if (this.props.filterI.length > 0) {
        filterI = "industry=";
        this.props.filterI.map((filter) => {
          filterI = filterI.concat(filter + ",");
          return false;
        });
      }
      if (this.props.filterC.length > 0) {
        filterC = "headquarter=";
        this.props.filterC.map((filter) => {
          filterC = filterC.concat(filter + ",");
          return false;
        });
      }
      if (this.props.filterI.length > 0 && this.props.filter.length > 0) {
        let temp = "&";
        temp = temp.concat(filterC);
        filterC = temp;
      }

      fetch(
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
          "/api/surveys/filter?" +
          filterI +
          filterC
      )
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            surveys: result,
          });
        });
    } else {
      fetch(
        (process.env.REACT_APP_API_URL || "http://localhost:3000") +
          "/api/surveys",
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((surveys) => {
          this.setState({ surveys: surveys });
        });
    }
  };

  onCheckboxClick = (key, survey) => {
    //if the radio is currently active, turn it off

    if (this.radioList[key]) {
      this.radioList = {};
      this.props.defocus(survey);
    } else if (this.state.allCheck) {
      this.setState({ allCheck: false });
    }

    if (this.checkboxList[key] === false) {
      this.checkboxList[key] = true;
      this.props.add(survey);
    } else {
      this.checkboxList[key] = false;
      this.props.remove(survey);
    }

    //if allCheck is active, turn it off
    this.setState({ update: true });
  };

  onRadioClick = (key, survey) => {
    // if the same radio is clicked
    if (key in this.radioList) {
      this.radioList = {};
      this.props.defocus(survey);
      // if all check toggled, check the checkbox when undoing radio
      if (this.state.allCheck) {
        this.setState({ allCheck: false });
      }
    } else {
      if (this.state.allCheck) {
        for (let currentRadio in this.radioList) {
          this.checkboxList[currentRadio] = true;
          this.props.transfer();
        }
      }
      this.radioList = { [key]: true };
      this.props.focus(survey);
      this.checkboxList[key] = false;
      this.props.remove(survey);
    }
    this.setState({ update: true });
  };

  onSelectAll = (boolean) => {
    let rKey;
    for (rKey in this.radioList) {
    }
    this.state.surveys.map((survey) => {
      if (Number(survey.survey_id) !== Number(rKey)) {
        this.checkboxList[survey.survey_id] = !boolean;
      }
    });
    if (boolean) {
      this.radioList = {};
      this.props.allDeselect();
    } else {
      this.props.allSelect(this.state.surveys);
    }
    this.setState({ allCheck: !boolean });
  };

  onSearchSurvey = () => {
    if (this.state.surveys.length > 0) {
      let newSurveyList = [];
      this.state.surveys.map((survey) => {
        if (survey.company.includes(this.props.searchSurvey)) {
          newSurveyList = [...newSurveyList, survey];
        }
      });
      return newSurveyList.map(
        (survey) => (
          survey.survey_id in this.checkboxList
            ? null
            : (this.checkboxList[survey.survey_id] = false),
          (
            <Table.Row key={survey.survey_id}>
              <Table.Cell>{survey.company}</Table.Cell>
              <Table.Cell>
                {survey.survey_creation_date.substring(0, 10)}
              </Table.Cell>
              <Table.Cell>{survey.entry_code}</Table.Cell>
              <Table.Cell>{survey.industry}</Table.Cell>
              <Table.Cell>{survey.headquarter}</Table.Cell>
              <Table.Cell>{survey.actual_responses}</Table.Cell>
              {/*CHANGE FROM EXPECTED TO COUNTRY SIZE ONCE*/}
              <Table.Cell>
                <input
                  checked={this.radioList[survey.survey_id]}
                  type="radio"
                  id={"radio" + survey.survey_id}
                  name="form"
                  onClick={() => this.onRadioClick(survey.survey_id, survey)}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  checked={this.checkboxList[survey.survey_id]}
                  onClick={() => this.onCheckboxClick(survey.survey_id, survey)}
                />
              </Table.Cell>
            </Table.Row>
          )
        )
      );
    }
  };

  generateItems = () => {
    if (this.state.surveys.length > 0) {
      if (this.props.searchSurvey) {
        let newSurveyList = [];
        this.state.surveys.map((survey) => {
          if (survey.company.includes(this.props.searchSurvey)) {
            newSurveyList = [...newSurveyList, survey];
          }
        });
        return newSurveyList.map(
          (survey) => (
            survey.survey_id in this.checkboxList
              ? null
              : (this.checkboxList[survey.survey_id] = false),
            (
              <Table.Row key={survey.survey_id}>
                <Table.Cell>{survey.company}</Table.Cell>
                <Table.Cell>
                  {survey.survey_creation_date.substring(0, 10)}
                </Table.Cell>
                <Table.Cell>{survey.entry_code}</Table.Cell>
                <Table.Cell>{survey.industry}</Table.Cell>
                <Table.Cell>{survey.headquarter}</Table.Cell>
                <Table.Cell>{survey.actual_responses}</Table.Cell>
                <Table.Cell>{survey.company_size}</Table.Cell>
                <Table.Cell>
                  <input
                    checked={this.radioList[survey.survey_id]}
                    type="radio"
                    id={"radio" + survey.survey_id}
                    name="form"
                    onClick={() => this.onRadioClick(survey.survey_id, survey)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={this.checkboxList[survey.survey_id]}
                    onClick={() =>
                      this.onCheckboxClick(survey.survey_id, survey)
                    }
                  />
                </Table.Cell>
              </Table.Row>
            )
          )
        );
      } else {
        return this.state.surveys.map(
          (survey) => (
            survey.survey_id in this.checkboxList
              ? null
              : (this.checkboxList[survey.survey_id] = false),
            (
              <Table.Row key={survey.survey_id}>
                <Table.Cell>{survey.company}</Table.Cell>
                <Table.Cell>
                  {survey.survey_creation_date.substring(0, 10)}
                </Table.Cell>
                <Table.Cell>{survey.entry_code}</Table.Cell>
                <Table.Cell>{survey.industry}</Table.Cell>
                <Table.Cell>{survey.headquarter}</Table.Cell>
                <Table.Cell>{survey.actual_responses}</Table.Cell>
                <Table.Cell>{survey.company_size}</Table.Cell>
                <Table.Cell>
                  <input
                    checked={this.radioList[survey.survey_id]}
                    type="radio"
                    id={"radio" + survey.survey_id}
                    name="form"
                    onClick={() => this.onRadioClick(survey.survey_id, survey)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={this.checkboxList[survey.survey_id]}
                    onClick={() =>
                      this.onCheckboxClick(survey.survey_id, survey)
                    }
                  />
                </Table.Cell>
              </Table.Row>
            )
          )
        );
      }
    }
  };

  render() {
    return (
      <div className="surveyView">
        <div className="surveyTitle" style={{ position: "sticky", top: 0 }}>
          Survey Overview
        </div>
        <Table striped>
          <Table.Header style={{ position: "sticky", top: 0 }}>
            <Table.Row>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>
                Industry{" "}
                <Icon
                  style={{ cursor: "pointer" }}
                  name="filter"
                  onClick={() => {
                    this.props.filter(this.state.industries, "Industry");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Headquarter{" "}
                <Icon
                  style={{ cursor: "pointer" }}
                  name="filter"
                  onClick={() => {
                    this.props.filter(this.state.countries, "Country");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Respondents</Table.HeaderCell>
              <Table.HeaderCell>Company Size</Table.HeaderCell>
              <Table.HeaderCell>Focus</Table.HeaderCell>
              <Table.HeaderCell>
                Select all{" "}
                <Checkbox
                  checked={this.state.allCheck}
                  onClick={() =>
                    this.onSelectAll(this.state.allCheck, this.state.surveys)
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.generateItems()}</Table.Body>
        </Table>
      </div>
    );
  }
}

SurveyOverview.propTypes = {
  surveys: propTypes.arrayOf(
    propTypes.shape({
      survey_id: propTypes.number.isRequired,
      company: propTypes.string.isRequired,
      survey_creation_date: propTypes.string.isRequired,
      entry_code: propTypes.string.isRequired,
      company_size: propTypes.string.isRequired,
      actual_responses: propTypes.number.isRequired,
      industry: propTypes.string.isRequired,
      headquarter: propTypes.string.isRequired,
      created_by: propTypes.number.isRequired,
    })
  ),
};

export default SurveyOverview;
