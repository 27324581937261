import React, { Component } from 'react';
import {Button, Icon} from 'semantic-ui-react';
import '../Dashboard.css';

class SurveyGraphHeader extends Component{
 
    state = {rHeader : 'Focus', oHeader : 'Industry'}

    componentDidMount(){
        if(this.props.isQuest){
            this.setState({
                rHeader: 'You',
                oHeader: 'Others'
            })
        }else if(this.props.justFocus){
            this.setState({
                rHeader: 'Average',
                oHeader: 'Individual'
            })
        }
    }

    render() {
        return (
            <div className='graphHeader'>
                <div className='graphLegend'>
                    <div className='legendDot_wrap'>
                    <div className='legendRed'/> 
                        <p className='legendRFont'>{this.state.rHeader}</p>
                    </div>
                    <div className='legendDot_wrap'>
                        <p className='legendOFont'>{this.state.oHeader}</p>
                        <div className='legendOrange'/> 
                    </div>
                </div>
                {this.props.hideFullscreenBtn?null:
                    <div className='graphFullscreenBtn'>
                        <Button onClick={() => {this.props.onFullscreen(this.props.id, this.props.s, this.props.f, this.props.justFocus)}} icon className='buttonStyle'><Icon size='large' name='expand'/></Button>
                    </div>
                }  
            </div>
        );
    }
}

export default SurveyGraphHeader;