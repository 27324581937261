import React, { Component } from 'react';
import SurveyGraphLine from './SurveyGraphLine';
import SurveyGraphScatter from './SurveyGraphScatter';
import '../Dashboard.css';
import { Button, Icon } from 'semantic-ui-react';

class SurveyGraphFullscreen extends Component {
    constructor(props){
        super(props)
        this.show = true
    }

    renderGraph = () => {
        if(this.props.id === 'line'){
            return <SurveyGraphLine surveyData={this.props.surveyData} focusData={this.props.focusData} hideFullscreenBtn={true} justFocus={this.props.justFocus}/>
        }else if(this.props.id === 'scatter'){
            return <SurveyGraphScatter surveyData={this.props.surveyData} focusData={this.props.focusData} hideFullscreenBtn={true} justFocus={this.props.justFocus}/>
        }
    }

    render() {
        return (
            <div>
                <div className='graphFullscreenTint'/>
                <div className='graphFullscreen'>
                    <div className='graphFullscreenReturnBtn'>
                      <Button color={'blue'} onClick={() => this.props.onFullscreen()} className='graphRtnBtn' icon><Icon size='big' name="arrow alternate circle left outline"/></Button>
                    </div>
                    <div className='graphFullscreenWrap'>
                        {this.renderGraph()}
                    </div>
                    {/*<Button style={{float:'right', marginRight:'20%'}} color='orange'>Export</Button>*/}
                </div>
            </div>
        );
    }
}

export default SurveyGraphFullscreen;