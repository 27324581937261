import React, { Component } from 'react'
import {Input, Form} from 'semantic-ui-react';


export default class UserEditForm extends Component {
    constructor(){
        super()
    }

    state = {
        first_name: undefined, 
        last_name: undefined, 
        email_address: undefined, 
        PASSWORD: undefined,
        new_first_name: "", 
        new_last_name: "", 
        new_email_address: "", 
        new_PASSWORD: ""

    }

    onEditChange = (e, {name, value}) => {
        this.setState({[name]: value})
    }

    onEditUserSubmit = (user) => {
        let first_name, last_name, email_address, PASSWORD
        if(this.state.first_name){first_name = this.state.first_name}else{first_name = this.props.selectedUser.first_name}
        if(this.state.last_name){last_name = this.state.last_name}else{last_name = this.props.selectedUser.last_name}
        if(this.state.email_address){email_address = this.state.email_address}else{email_address = this.props.selectedUser.email_address}
        if(this.state.PASSWORD){PASSWORD = this.state.PASSWORD}else{PASSWORD = this.props.selectedUser.PASSWORD}
        let data= {first_name, last_name, email_address, PASSWORD}

        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/employees/' + user.admin_id, {
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        .then(response => response.json())
        .then((result) => {
            this.props.fetchUsers()
        })
        .catch(Error => {
            console.log("Error:")
            console.log(Error)
        })

        this.props.onEditExit()
    }

    onNewUserSubmit = () => {
        let data = {
            first_name: this.state.new_first_name,
            last_name: this.state.new_last_name,
            email_address: this.state.new_email_address,
            password: this.state.new_PASSWORD,
            role: "admin",
            permission: true
        }

        fetch((process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/api/employees/',{
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        .then(response => response.json())
        .then((result) => {
            this.props.fetchUsers()
        })
        .catch(Error => {
            console.log("Error:")
            console.log(Error)
        })
        this.props.onEditExit()
    }
    
    renderNeworEdit = () => {
        if(this.props.isEdit){
            return(
                <Form className="newSurveyForm" onSubmit={() => this.onEditUserSubmit(this.props.selectedUser)}>
                    <Form.Field>
                        <p className={"editFormHeaders"}>{this.props.selectedUser.first_name} </p>
                        <Input placeholder='First Name'
                        name="first_name"
                        value={this.state.first_name} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                    <p className={"editFormHeaders"}>  {this.props.selectedUser.last_name} </p>
                        <Input placeholder='Last Name'
                        name="last_name"
                        value={this.state.last_name} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                    <p className={"editFormHeaders"}> {this.props.selectedUser.email_address} </p>
                        <Input placeholder='Email Address'
                        name="email_address"
                        value={this.state.email_address} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                    <p className={"editFormHeaders"}> {this.props.selectedUser.PASSWORD}</p>
                        <Input placeholder='Password'
                        name="PASSWORD"
                        value={this.state.PASSWORD} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Button
                        content="Submit"
                    />
                </Form>
            )
        } else {
            return(
                <Form className="newSurveyForm" onSubmit={() => this.onNewUserSubmit()}>
                    <Form.Field>
                        <Input placeholder='First Name'
                        name="new_first_name"
                        value={this.state.new_first_name} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Input placeholder='Last Name'
                        name="new_last_name"
                        value={this.state.new_last_name} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Input placeholder='Email Address'
                        name="new_email_address"
                        value={this.state.new_email_address} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Input placeholder='Password'
                        name="new_PASSWORD"
                        value={this.state.new_PASSWORD} onChange={this.onEditChange}/>
                    </Form.Field>
                    <Form.Button
                        content="Submit"
                    />
                 </Form>
            )
        }
    }
    
    render() {
        return (
            <div>
                {this.renderNeworEdit()}
            </div>
        )
    }
}
