import React, { Component } from "react";
import {
  LineChart,
  ScatterChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Scatter,
  Line,
  Tooltip,
} from "recharts";
import LineBackground from "../../../assets/images/line.png";
import SurveyGraphHeader from "./SurveyGraphHeader";
import "../Dashboard.css";
import { curveValue } from "./curveValue";

const lineData = curveValue;

class SurveyGraphLine extends Component {
  constructor(props) {
    super(props);
    this.val_float = "left";
  }

  componentDidMount() {
    if (this.props.hideFullscreenBtn) {
      this.val_float = "right";
    } else {
      this.val_float = "left";
    }
  }

  dataFuzziness = (data) => {
    //DOESN'T WORK WOR LINE, RETURNS ORIGINAL DATA
    let fuzzyData = [];
    data.map((point) => {
      let Xvalue, Yvalue;
      //generates random number between -0.2 and 0.2
      Xvalue = point.Xvalue + (Math.floor(Math.random() * 40) - 20) / 100;
      Yvalue = point.Yvalue + (Math.floor(Math.random() * 40) - 20) / 100;

      //rounds number to 2 decimal place
      Xvalue = Math.round(Xvalue * 100) / 100;
      Yvalue = Math.round(Yvalue * 100) / 100;

      //if cases for points outside boundaries
      if (Xvalue > 5) {
        Xvalue = 5;
      } else if (Xvalue < 0) {
        Xvalue = 0;
      }

      if (Yvalue > 5) {
        Yvalue = 5;
      } else if (Yvalue < 0) {
        Yvalue = 0;
      }

      //add to array
      fuzzyData.push({ Xvalue, Yvalue });
    });

    return data;
  };

  render() {
    return (
      <div className="surveyLine">
        <div className="graphTitle">
          <p style={{ float: "left", fontWeight: "bold" }}>Maturity Levels</p>
          <p style={{ float: "right" }}>
            {this.props.justFocus
              ? this.props.surveyData.length
              : this.props.surveyData.length + this.props.focusData.length}{" "}
            Respondents
          </p>
        </div>
        <div className="imgWrap2">
          <img alt={""} className="graphIMG2" src={LineBackground} />
        </div>
        <SurveyGraphHeader
          justFocus={this.props.justFocus}
          s={this.props.surveyData}
          f={this.props.focusData}
          id="line"
          onFullscreen={this.props.onFullscreen}
          hideFullscreenBtn={this.props.hideFullscreenBtn}
          isQuest={this.props.isQuest}
        />
        <div className="lineWrapper">
          <ResponsiveContainer>
            <LineChart data={lineData}>
              <Line
                type="monotone"
                dataKey="y"
                stroke="#003769"
                strokeWidth={3}
                dot={false}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="lineWrapper">
          <ResponsiveContainer>
            <ScatterChart>
              <XAxis type="number" dataKey="Xvalue" domain={[0, 5]} hide />
              <YAxis type="number" dataKey="Yvalue" domain={[0, 290]} hide />
              <Scatter
                name="surveys"
                data={this.dataFuzziness(this.props.surveyData)}
                fill="#F48800"
                isAnimationActive={false}
              />
              <Scatter
                name="focus"
                stroke="#EB1E23"
                strokeWidth={5}
                data={this.props.focusData}
                fill="#EB1E23"
                isAnimationActive={false}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default SurveyGraphLine;
